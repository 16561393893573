<template>
  <div id="app">
    <section class="hero is-large">
      <div class="hero-head">
        <nav class="navbar">
          <div class="container">
            <div class="navbar-brand">
              <a class="navbar-item" href="#">
                <!-- <img src="./images/logo.png" alt="Queen's Pizza Logo" width="28" height="28"> -->
                <div class="menu-text text-is-red pl-2">Queen's Pizza</div>
              </a>

              <a
                role="button"
                class="navbar-burger burger"
                aria-label="menu"
                aria-expanded="false"
                data-target="navbar-right"
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </div>
            <div id="navbar-right" class="navbar-menu">
              <div class="navbar-end">
                <div class="navbar-item has-dropdown is-hoverable">
                  <a
                    class="navbar-link menu-text text-is-red"
                    href="#favourites"
                  >
                    Menu
                  </a>
                  <div class="navbar-dropdown is-boxed">
                    <a
                      class="navbar-item menu-text text-is-red"
                      href="#favourites"
                    >
                      Favourites
                    </a>
                    <a
                      class="navbar-item menu-text text-is-red"
                      href="#combo-section"
                    >
                      Combos
                    </a>
                    <a
                      class="navbar-item menu-text text-is-red"
                      href="#wings-section"
                    >
                      Wings
                    </a>
                    <a
                      class="navbar-item menu-text text-is-red"
                      href="#side-orders-section"
                    >
                      Sides
                    </a>
                    <a
                      class="navbar-item menu-text text-is-red"
                      href="#toppings-section"
                    >
                      Toppings
                    </a>
                  </div>
                </div>
                <a class="navbar-item menu-text text-is-red" href="#location">
                  Location
                </a>
                <a class="navbar-item menu-text text-is-red" href="#hours">
                  Hours
                </a>
                <a
                  class="navbar-item menu-text text-is-red"
                  href="#contact-section"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div id="main-hero-body" class="hero-body">
        <div class="container has-text-centered center-button">
          <h1 class="title outline-text is-spaced is-size-1 fancy-text">
            Queen's Pizza & Wings
          </h1>

          <button
            id="order-button"
            class="button is-black is-inverted has-shadow"
          >
            <a href="#order-here">
              <div class="text-is-red menu-text">Call or Order Online</div>
            </a>
          </button>

          <!-- <a href="https://www.doordash.com/business/507639/?utm_source=partner-link&utm_medium=website&utm_campaign=507639&utm_content=red-m"
                    target="_blank" alt="Order Food Delivery with DoorDash" title="Order Food Delivery with DoorDash"
                    style="text-decoration: none">
                    <div
                        style="position: relative; width:209px; height:45px; margin: 0px auto; background-image: url(https://cdn.doordash.com/media/button/button_red_m.svg); color:transparent">
                        Order Food Delivery with DoorDash</div>
                </a> -->
        </div>
      </div>
    </section>
    <section id="favourites" class="section has-background-white">
      <h1 class="title is-1 has-text-centered fancy-text pb-5">Favourites</h1>
      <div class="container is-small">
        <div class="columns is-centered has-text-centered">
          <div class="column is-half">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                  is-mobile
                "
              >
                Pick-up Deals
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                  is-mobile
                "
              >
                ~$
              </div>
            </div>
            <div class="has-text-centered menu-text is-uppercase pb-5">
              1 Pizza + 1 dipping sauce
            </div>
            <table
              class="
                menu-text
                table
                is-bordered is-striped is-narrow is-hoverable is-fullwidth
              "
            >
              <thead>
                <tr>
                  <th>Size</th>
                  <th>1 Topping</th>
                  <th>3 Toppings</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Small</th>
                  <td>$7.99</td>
                  <td>$9.99</td>
                </tr>
                <tr>
                  <th>Medium</th>
                  <td>$8.99</td>
                  <td>$10.99</td>
                </tr>
                <tr>
                  <th>Large</th>
                  <td>$9.99</td>
                  <td>$12.99</td>
                </tr>
                <tr>
                  <th>X-Large</th>
                  <td>$11.99</td>
                  <td>$14.99</td>
                </tr>
                <tr>
                  <th>Jumbo</th>
                  <td>$14.99</td>
                  <td>$17.99</td>
                </tr>
                <tr>
                  <th>Slab</th>
                  <td>$19.99</td>
                  <td>$23.99</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="columns">
          <div class="column is-half">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                  is-mobile
                "
              >
                Queen's Deal
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                  is-mobile
                "
              >
                $39.99
              </div>
            </div>
            <ul>
              <li class="is-uppercase menu-text menu-description">
                2 Medium Pizzas
              </li>
              <li class="is-uppercase menu-text menu-description">
                3 Toppings on each
              </li>
              <li class="is-uppercase menu-text menu-description">
                20 Chicken Wings
              </li>
              <li class="is-uppercase menu-text menu-description">
                3 Cans of Pop
              </li>
              <li class="is-uppercase menu-text menu-description">
                1 Garlic Bread
              </li>
              <li class="is-uppercase menu-text menu-description">
                2 Free Dipping Sauce
              </li>
              <li class="is-uppercase menu-text menu-description">
                1 Choice of Salad: Garden or Caesar
              </li>
            </ul>
          </div>
          <div class="column is-half">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                "
              >
                Shawarma Pizza
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                "
              >
                ~$12.99+
              </div>
            </div>
            <ul>
              <li
                class="
                  is-uppercase
                  menu-text menu-description
                  has-text-centered
                "
              >
                Chicken Shawarma
              </li>
              <li
                class="
                  is-uppercase
                  menu-text menu-description
                  has-text-centered
                "
              >
                -OR-
              </li>
              <li
                class="
                  is-uppercase
                  menu-text menu-description
                  has-text-centered
                "
              >
                Beef Shawarama
              </li>
            </ul>
            <table
              class="
                menu-text
                table
                is-bordered is-striped is-narrow is-hoverable is-fullwidth
                mt-5
              "
            >
              <thead>
                <tr>
                  <th>Size</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Small</th>
                  <td>$12.99</td>
                </tr>
                <tr>
                  <th>Medium</th>
                  <td>$14.99</td>
                </tr>
                <tr>
                  <th>Large</th>
                  <td>$15.99</td>
                </tr>
                <tr>
                  <th>X-Large</th>
                  <td>$17.99</td>
                </tr>
                <tr>
                  <th>Jumbo</th>
                  <td>$20.99</td>
                </tr>
                <tr>
                  <th>Slab</th>
                  <td>$24.99</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-dark is-medium">
      <div id="combo-section" class="hero-body">
        <div class="container">
          <h1 class="title is-1 has-text-centered fancy-text outline-text">
            Combos
          </h1>
          <h2 class="title is-4 has-text-centered fancy-text outline-text">
            Delicious deals with great value!
          </h2>
        </div>
      </div>
    </section>
    <section id="combos" class="section has-background-white">
      <h2 class="title is-2 has-text-centered fancy-text pb-5">
        Pizza & Wing Combos
      </h2>
      <div class="container is-small">
        <div class="columns">
          <div class="column is-one-quarter">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                "
              >
                Combo 4A
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                "
              >
                $27.99
              </div>
            </div>
            <ul>
              <li class="is-uppercase menu-text menu-description">
                1 Medium Pizza
              </li>
              <li class="is-uppercase menu-text menu-description">
                3 Toppings
              </li>
              <li class="is-uppercase menu-text menu-description">
                15 Chicken Wings
              </li>
              <li class="is-uppercase menu-text menu-description">
                1 Garlic Bread
              </li>
              <li class="is-uppercase menu-text menu-description">
                1 Free Dipping Sauce
              </li>
            </ul>
          </div>
          <div class="column is-one-quarter">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                "
              >
                Combo 4B
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                "
              >
                $29.99
              </div>
            </div>
            <ul>
              <li class="is-uppercase menu-text menu-description">
                1 Large Pizza
              </li>
              <li class="is-uppercase menu-text menu-description">
                3 Toppings
              </li>
              <li class="is-uppercase menu-text menu-description">
                15 Chicken Wings
              </li>
              <li class="is-uppercase menu-text menu-description">
                1 Garlic Bread
              </li>
              <li class="is-uppercase menu-text menu-description">
                1 Free Dipping Sauce
              </li>
            </ul>
          </div>
          <div class="column is-one-quarter">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                "
              >
                Combo 4C
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                "
              >
                $31.99
              </div>
            </div>
            <ul>
              <li class="is-uppercase menu-text menu-description">
                1 X-Large Pizza
              </li>
              <li class="is-uppercase menu-text menu-description">
                3 Toppings
              </li>
              <li class="is-uppercase menu-text menu-description">
                15 Chicken Wings
              </li>
              <li class="is-uppercase menu-text menu-description">
                1 Garlic Bread
              </li>
              <li class="is-uppercase menu-text menu-description">
                1 Free Dipping Sauce
              </li>
            </ul>
          </div>
          <div class="column is-one-quarter">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                "
              >
                Combo 4D
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                "
              >
                $38.99
              </div>
            </div>
            <ul>
              <li class="is-uppercase menu-text menu-description">
                1 Jumbo Pizza
              </li>
              <li class="is-uppercase menu-text menu-description">
                3 Toppings
              </li>
              <li class="is-uppercase menu-text menu-description">
                20 Chicken Wings
              </li>
              <li class="is-uppercase menu-text menu-description">
                1 Garlic Bread
              </li>
              <li class="is-uppercase menu-text menu-description">
                1 Free Dipping Sauce
              </li>
            </ul>
          </div>
        </div>
        <h2 class="title is-2 has-text-centered fancy-text pb-5 pt-5">
          Family Combos
        </h2>
        <div class="columns">
          <div class="column is-one-third">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                "
              >
                Combo 5A
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                "
              >
                $36.99
              </div>
            </div>
            <ul>
              <li class="is-uppercase menu-text menu-description">
                2 Medium Pizzas
              </li>
              <li class="is-uppercase menu-text menu-description">
                3 Toppings
              </li>
              <li class="is-uppercase menu-text menu-description">
                20 Chicken Wings
              </li>
              <li class="is-uppercase menu-text menu-description">
                1 Garlic Bread
              </li>
              <li class="is-uppercase menu-text menu-description">
                2 Free Dipping Sauce
              </li>
            </ul>
          </div>
          <div class="column is-one-third">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                "
              >
                Combo 5B
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                "
              >
                $38.99
              </div>
            </div>
            <ul>
              <li class="is-uppercase menu-text menu-description">
                2 Large Pizzas
              </li>
              <li class="is-uppercase menu-text menu-description">
                3 Toppings
              </li>
              <li class="is-uppercase menu-text menu-description">
                20 Chicken Wings
              </li>
              <li class="is-uppercase menu-text menu-description">
                1 Garlic Bread
              </li>
              <li class="is-uppercase menu-text menu-description">
                2 Free Dipping Sauces
              </li>
            </ul>
          </div>
          <div class="column is-one-third">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                "
              >
                Combo 5C
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                "
              >
                $41.99
              </div>
            </div>
            <ul>
              <li class="is-uppercase menu-text menu-description">
                2 X-Large Pizzas
              </li>
              <li class="is-uppercase menu-text menu-description">
                3 Toppings
              </li>
              <li class="is-uppercase menu-text menu-description">
                20 Chicken Wings
              </li>
              <li class="is-uppercase menu-text menu-description">
                1 Garlic Bread
              </li>
              <li class="is-uppercase menu-text menu-description">
                2 Free Dipping Sauces
              </li>
            </ul>
          </div>
        </div>
        <h2 class="title is-2 has-text-centered fancy-text pb-5 pt-5">
          Single Pizza Combos
        </h2>
        <div class="columns">
          <div class="column is-one-quarter">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                "
              >
                Combo 1A
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                "
              >
                $14.99
              </div>
            </div>
            <ul>
              <li class="is-uppercase menu-text menu-description">
                1 Medium Pizza
              </li>
              <li class="is-uppercase menu-text menu-description">
                3 Toppings
              </li>
              <li class="is-uppercase menu-text menu-description">
                1 Free Dipping Sauce
              </li>
            </ul>
          </div>
          <div class="column is-one-quarter">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                "
              >
                Combo 1B
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                "
              >
                $15.99
              </div>
            </div>
            <ul>
              <li class="is-uppercase menu-text menu-description">
                1 Large Pizza
              </li>
              <li class="is-uppercase menu-text menu-description">
                3 Toppings
              </li>
              <li class="is-uppercase menu-text menu-description">
                1 Free Dipping Sauce
              </li>
            </ul>
          </div>
          <div class="column is-one-quarter">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                "
              >
                Combo 1C
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                "
              >
                $17.99
              </div>
            </div>
            <ul>
              <li class="is-uppercase menu-text menu-description">
                1 X-Large Pizza
              </li>
              <li class="is-uppercase menu-text menu-description">
                3 Toppings
              </li>
              <li class="is-uppercase menu-text menu-description">
                1 Free Dipping Sauce
              </li>
            </ul>
          </div>
          <div class="column is-one-quarter">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                "
              >
                Combo 1D
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                "
              >
                $19.99
              </div>
            </div>
            <ul>
              <li class="is-uppercase menu-text menu-description">
                1 Jumbo Pizza
              </li>
              <li class="is-uppercase menu-text menu-description">
                3 Toppings
              </li>
              <li class="is-uppercase menu-text menu-description">
                1 Free Dipping Sauce
              </li>
            </ul>
          </div>
        </div>
        <h2 class="title is-2 has-text-centered fancy-text pb-5 pt-5">
          Double Pizza Combos
        </h2>
        <div class="columns">
          <div class="column is-one-third">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                "
              >
                Combo 2A
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                "
              >
                $25.99
              </div>
            </div>
            <ul>
              <li class="is-uppercase menu-text menu-description">
                2 Medium Pizzas
              </li>
              <li class="is-uppercase menu-text menu-description">
                3 Toppings on each
              </li>
              <li class="is-uppercase menu-text menu-description">
                2 Free Dipping Sauce
              </li>
            </ul>
          </div>
          <div class="column is-one-third">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                "
              >
                Combo 2B
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                "
              >
                $28.99
              </div>
            </div>
            <ul>
              <li class="is-uppercase menu-text menu-description">
                2 Large Pizzas
              </li>
              <li class="is-uppercase menu-text menu-description">
                3 Toppings on each
              </li>
              <li class="is-uppercase menu-text menu-description">
                2 Free Dipping Sauces
              </li>
            </ul>
          </div>
          <div class="column is-one-third">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                "
              >
                Combo 2C
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                "
              >
                $30.99
              </div>
            </div>
            <ul>
              <li class="is-uppercase menu-text menu-description">
                2 X-Large Pizzas
              </li>
              <li class="is-uppercase menu-text menu-description">
                3 Toppings on each
              </li>
              <li class="is-uppercase menu-text menu-description">
                2 Free Dipping Sauces
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-dark is-medium">
      <div id="wings-section" class="hero-body">
        <div class="container">
          <h1 class="title is-1 has-text-centered fancy-text outline-text">
            Wings
          </h1>
          <h2 class="title is-4 has-text-centered fancy-text outline-text">
            Deep fried goodness!
          </h2>
        </div>
      </div>
    </section>
    <section id="wings" class="section has-background-white">
      <div class="container">
        <!-- <h2 class="title is-2 has-text-centered fancy-text pb-5">Wings n' Stuff</h2> -->
        <div class="columns is-centered has-text-centered">
          <div class="column is-half">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                  is-mobile
                "
              >
                Chicken Wings
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                  is-mobile
                "
              ></div>
            </div>
            <table
              class="
                menu-text
                table
                is-bordered is-striped is-narrow is-hoverable is-fullwidth
              "
            >
              <thead>
                <tr>
                  <th>Total Wings</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>10</th>
                  <td>$11.99</td>
                </tr>
                <tr>
                  <th>20</th>
                  <td>$20.99</td>
                </tr>
                <tr>
                  <th>30</th>
                  <td>$27.99</td>
                </tr>
              </tbody>
            </table>
            <div class="has-text-centered menu-text is-uppercase pb-5">
              Sauces: Mild BBQ, Medium BBQ, Frank's Red Hot, Suicide, Honey
              Garlic
            </div>
            <div class="has-text-centered menu-text is-uppercase pb-5">
              All wings come with garlic bread<br />Add cheese to bread for
              $1.49!
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-third">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                  is-mobile
                "
              >
                Panzerotti
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                  is-mobile
                "
              >
                $12.99
              </div>
            </div>
            <ul>
              <li class="is-uppercase menu-text menu-description">
                1 Panzerotti
              </li>
              <li class="is-uppercase menu-text menu-description">
                3 Toppings
              </li>
              <li class="is-uppercase menu-text menu-description">
                1 Marinara dip
              </li>
            </ul>
          </div>
          <div class="column is-one-third">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                  is-mobile
                "
              >
                Panzerotti Combo
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                  is-mobile
                "
              >
                $35.99
              </div>
            </div>
            <ul>
              <li class="is-uppercase menu-text menu-description">
                2 Panzerotties
              </li>
              <li class="is-uppercase menu-text menu-description">
                3 Toppings on each
              </li>
              <li class="is-uppercase menu-text menu-description">
                20 Chicken Wings
              </li>
              <li class="is-uppercase menu-text menu-description">
                1 Garlic Bread
              </li>
            </ul>
          </div>
          <div class="column is-one-third">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                "
              >
                Slab Pizza
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                "
              ></div>
            </div>
            <ul>
              <li
                class="
                  is-uppercase
                  menu-text menu-description
                  has-text-centered
                "
              >
                1 Slab Pizza
              </li>
              <li
                class="
                  is-uppercase
                  menu-text menu-description
                  has-text-centered
                "
              >
                2 Free Dipping Sauces
              </li>
            </ul>
            <table
              class="
                menu-text
                table
                is-bordered is-striped is-narrow is-hoverable is-fullwidth
                mt-5
              "
            >
              <thead>
                <tr>
                  <th># of Toppings</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>1</th>
                  <td>$20.99</td>
                </tr>
                <tr>
                  <th>3</th>
                  <td>$24.99</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-dark is-medium">
      <div id="side-orders-section" class="hero-body">
        <div class="container">
          <h1 class="title is-1 has-text-centered fancy-text outline-text">
            Side Orders
          </h1>
          <h2 class="title is-4 has-text-centered fancy-text outline-text">
            Extras to go with your pizza or wings!
          </h2>
        </div>
      </div>
    </section>
    <section id="wings" class="section has-background-white">
      <div class="container">
        <!-- <h2 class="title is-2 has-text-centered fancy-text pb-5">Side Orders</h2> -->
        <div class="columns">
          <div class="column is-half">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                  is-mobile
                "
              >
                Sides
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                  is-mobile
                "
              ></div>
            </div>
            <table
              class="
                menu-text
                table
                is-bordered is-striped is-narrow is-hoverable is-fullwidth
              "
            >
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Medium</th>
                  <th>Large</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>French Fries</th>
                  <td>$4.99</td>
                  <td>$5.99</td>
                </tr>
                <tr>
                  <th>Poutine</th>
                  <td>$7.99</td>
                  <td>$8.99</td>
                </tr>
                <tr>
                  <th>Shawarma Poutine</th>
                  <td>$8.99</td>
                  <td>$10.99</td>
                </tr>
                <tr>
                  <th>Onion Rings</th>
                  <td>$6.99</td>
                  <td>$7.99</td>
                </tr>
                <tr>
                  <th>Potato Wedges</th>
                  <td>$6.99</td>
                  <td>$7.99</td>
                </tr>
                <tr>
                  <th>Mozarella Sticks</th>
                  <td class="has-text-centered" colspan="2">
                    $6.99 (one size)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="column is-half">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                  is-mobile
                "
              >
                Burgers
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                  is-mobile
                "
              ></div>
            </div>
            <table
              class="
                menu-text
                table
                is-bordered is-striped is-narrow is-hoverable is-fullwidth
              "
            >
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Burger</th>
                  <td>$6.99</td>
                </tr>
                <tr>
                  <th>Burger & Fries</th>
                  <td>$9.99</td>
                </tr>
                <tr>
                  <th>Burger & Onion Rings</th>
                  <td>$10.99</td>
                </tr>
                <tr>
                  <th>Burger & Potato Wedges</th>
                  <td>$10.99</td>
                </tr>
                <tr>
                  <th>Add Cheese</th>
                  <td>+$1.00</td>
                </tr>
                <tr>
                  <th>Add Bacon</th>
                  <td>+$1.00</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-third">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                  is-mobile
                "
              >
                Subs
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                  is-mobile
                "
              ></div>
            </div>
            <table
              class="
                menu-text
                table
                is-bordered is-striped is-narrow is-hoverable is-fullwidth
              "
            >
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Pizza Sub</th>
                  <td>$7.99</td>
                </tr>
                <tr>
                  <th>Meatball Sub</th>
                  <td>$7.99</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="column is-one-third">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                  is-mobile
                "
              >
                Shawarma
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                  is-mobile
                "
              ></div>
            </div>
            <table
              class="
                menu-text
                table
                is-bordered is-striped is-narrow is-hoverable is-fullwidth
              "
            >
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Chicken or Beef Wrap</th>
                  <td>$8.99</td>
                </tr>
                <tr>
                  <th>Chicken Rice & Salad</th>
                  <td>$12.99</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="column is-one-third">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                  is-mobile
                "
              >
                Fish & Chips (Haddock)
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                  is-mobile
                "
              ></div>
            </div>
            <table
              class="
                menu-text
                table
                is-bordered is-striped is-narrow is-hoverable is-fullwidth
              "
            >
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>1Pc & Fries + Tartar</th>
                  <td>$10.99</td>
                </tr>
                <tr>
                  <th>2Pc & Fries + Tartar</th>
                  <td>$16.99</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-third">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                  is-mobile
                "
              >
                Garlic Bread
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                  is-mobile
                "
              ></div>
            </div>
            <table
              class="
                menu-text
                table
                is-bordered is-striped is-narrow is-hoverable is-fullwidth
              "
            >
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Garlic Bread</th>
                  <td>$2.99</td>
                </tr>
                <tr>
                  <th>Add Cheese</th>
                  <td>+$1.50</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="column is-one-third">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                  is-mobile
                "
              >
                Dessert
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                  is-mobile
                "
              ></div>
            </div>
            <table
              class="
                menu-text
                table
                is-bordered is-striped is-narrow is-hoverable is-fullwidth
              "
            >
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Cheese Cake Slice</th>
                  <td>$3.99</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="column is-one-third">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                  is-mobile
                "
              >
                Dipping Sauces
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                  is-mobile
                "
              ></div>
            </div>
            <table
              class="
                menu-text
                table
                is-bordered is-striped is-narrow is-hoverable is-fullwidth
              "
            >
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Garlic</th>
                  <td>$1.00</td>
                </tr>
                <tr>
                  <th>Ranch or Cheddar</th>
                  <td>$1.25</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="columns">
          <div class="column is-half">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                  is-mobile
                "
              >
                Veggies
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                  is-mobile
                "
              ></div>
            </div>
            <table
              class="
                menu-text
                table
                is-bordered is-striped is-narrow is-hoverable is-fullwidth
              "
            >
              <thead>
                <tr>
                  <th>Item</th>
                  <th colspan="2">Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Greek Salad</th>
                  <td>M: $7.99</td>
                  <td>L: $8.99</td>
                </tr>
                <tr>
                  <th>Caesar or Garden Salad</th>
                  <td>M: $6.99</td>
                  <td>L: $7.99</td>
                </tr>
                <tr>
                  <th>Chicken Salad</th>
                  <td class="has-text-centered" colspan="2">$8.99</td>
                </tr>
                <tr>
                  <th>Breaded Mushroom</th>
                  <td class="has-text-centered" colspan="2">$6.99</td>
                </tr>
                <tr>
                  <th>Jalapeno Poppers</th>
                  <td class="has-text-centered" colspan="2">$7.99</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="column is-half">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                  is-mobile
                "
              >
                Drinks
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                  is-mobile
                "
              ></div>
            </div>
            <table
              class="
                menu-text
                table
                is-bordered is-striped is-narrow is-hoverable is-fullwidth
              "
            >
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Bottled Water</th>
                  <td>$1.33</td>
                </tr>
                <tr>
                  <th>Pop Can</th>
                  <td>$1.33</td>
                </tr>
                <tr>
                  <th>Juice (Apple/Orange/Mango/Lemon)</th>
                  <td>$3.50</td>
                </tr>
                <tr>
                  <th>Chocolate Milk</th>
                  <td>$3.50</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <section id="toppings-section" class="section has-background-white">
          <div class="container">
            <h2 class="title is-2 has-text-centered fancy-text pb-5">
              Toppings
            </h2>
            <div class="columns">
              <div class="column is-one-third">
                <div class="level has-dotted-border-bottom">
                  <div
                    class="
                      level-left level-item
                      title
                      is-5
                      menu-text menu-size
                      text-is-red
                      mb-0
                    "
                  >
                    Meats
                  </div>
                  <div
                    class="
                      level-right level-item
                      menu-text menu-price
                      text-is-red
                      mb-0
                    "
                  ></div>
                </div>
                <ul>
                  <li class="is-uppercase menu-text menu-description">
                    Pepperoni
                  </li>
                  <li class="is-uppercase menu-text menu-description">Ham</li>
                  <li class="is-uppercase menu-text menu-description">
                    Bacon Bits
                  </li>
                  <li class="is-uppercase menu-text menu-description">
                    Bacon Strips
                  </li>
                  <li class="is-uppercase menu-text menu-description">
                    Chicken
                  </li>
                  <li class="is-uppercase menu-text menu-description">
                    Italian Sausage
                  </li>
                  <li class="is-uppercase menu-text menu-description">
                    Hot Sausage
                  </li>
                  <li class="is-uppercase menu-text menu-description">
                    Ground Beef
                  </li>
                  <li class="is-uppercase menu-text menu-description">
                    Anchovies
                  </li>
                  <li class="is-uppercase menu-text menu-description">
                    Halal Pepperoni
                  </li>
                  <li class="is-uppercase menu-text menu-description">
                    Halal Ground Beef
                  </li>
                </ul>
              </div>
              <div class="column is-one-third">
                <div class="level has-dotted-border-bottom">
                  <div
                    class="
                      level-left level-item
                      title
                      is-5
                      menu-text menu-size
                      text-is-red
                      mb-0
                    "
                  >
                    Vegetables
                  </div>
                  <div
                    class="
                      level-right level-item
                      menu-text menu-price
                      text-is-red
                      mb-0
                    "
                  ></div>
                </div>
                <ul>
                  <li class="is-uppercase menu-text menu-description">
                    Fresh Garlic
                  </li>
                  <li class="is-uppercase menu-text menu-description">
                    Mushroom
                  </li>
                  <li class="is-uppercase menu-text menu-description">
                    Green Peppers
                  </li>
                  <li class="is-uppercase menu-text menu-description">
                    Red Onions
                  </li>
                  <li class="is-uppercase menu-text menu-description">
                    Jalapeno Peppers
                  </li>
                  <li class="is-uppercase menu-text menu-description">
                    Pineapple
                  </li>
                  <li class="is-uppercase menu-text menu-description">
                    Tomatoes
                  </li>
                  <li class="is-uppercase menu-text menu-description">
                    Sundried Tomatoes
                  </li>
                  <li class="is-uppercase menu-text menu-description">
                    Hot Peppers
                  </li>
                  <li class="is-uppercase menu-text menu-description">
                    Green Olives
                  </li>
                  <li class="is-uppercase menu-text menu-description">
                    Black Olives
                  </li>
                  <li class="is-uppercase menu-text menu-description">
                    Red Peppers
                  </li>
                  <li class="is-uppercase menu-text menu-description">
                    Broccoli
                  </li>
                </ul>
              </div>
              <div class="column is-one-third">
                <div class="level has-dotted-border-bottom">
                  <div
                    class="
                      level-left level-item
                      title
                      is-5
                      menu-text menu-size
                      text-is-red
                      mb-0
                    "
                  >
                    Cheeses
                  </div>
                  <div
                    class="
                      level-right level-item
                      menu-text menu-price
                      text-is-red
                      mb-0
                    "
                  ></div>
                </div>
                <ul>
                  <li class="is-uppercase menu-text menu-description">
                    Extra Cheese
                  </li>
                  <li class="is-uppercase menu-text menu-description">
                    Feta Cheese
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <section class="hero is-dark is-medium">
      <div id="contact-section" class="hero-body">
        <div class="container">
          <h1 class="title is-1 has-text-centered fancy-text outline-text">
            Contact Us
          </h1>
          <h2 class="title is-4 has-text-centered fancy-text outline-text">
            Feel free to reach out to us!
          </h2>
        </div>
      </div>
    </section>
    <section class="section has-background-white">
      <div class="container">
        <!-- <h2 class="title is-2 has-text-centered fancy-text pb-5">Side Orders</h2> -->
        <div class="columns">
          <div class="column is-one-half">
            <div class="level has-dotted-border-bottom">
              <div
                id="location"
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                "
              >
                Location
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                "
              ></div>
            </div>
            <ul>
              <li class="is-uppercase menu-text menu-description">
                838 King St. E
              </li>
              <li class="is-uppercase menu-text menu-description">
                Hamilton, ON
              </li>
              <li class="is-uppercase menu-text menu-description">L8M 1B4</li>
            </ul>
            <div id="hours" class="level has-dotted-border-bottom mt-5">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                "
              >
                Opening Hours
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                "
              ></div>
            </div>
            <ul>
              <li class="is-uppercase menu-text menu-description">
                Monday: 11:00am - 12:00am
              </li>
              <li class="is-uppercase menu-text menu-description">
                Tuesday: 11:00am - 12:00am
              </li>
              <li class="is-uppercase menu-text menu-description">
                Wednesday: 11:00am - 12:00am
              </li>
              <li class="is-uppercase menu-text menu-description">
                Thursday: 11:00am - 12:00am
              </li>
              <li class="is-uppercase menu-text menu-description">
                Friday: 11:00am - 1:00am
              </li>
              <li class="is-uppercase menu-text menu-description">
                Saturday: 12:00pm - 1:00am
              </li>
              <li class="is-uppercase menu-text menu-description">
                Sunday: 12:00pm - 12:00am
              </li>
            </ul>
            <div id="order-here" class="level has-dotted-border-bottom mt-5">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                "
              >
                Order Here
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                "
              ></div>
            </div>
            <ul>
              <li class="is-uppercase menu-text menu-description">
                Phone Number: <a href="tel:+1-905-545-0101">+1-905-545-0101</a>
              </li>
              <li class="is-uppercase menu-text menu-description pt-1">
                Online Ordering:
                <a
                  href="https://onlineordering.mealsy.ca/en/#/QueensPizzaHamilton/online/menus"
                  target="_blank"
                  alt="Place an order online!"
                  title="Place an order online!"
                  style="text-decoration: none"
                  rel="noopener"
                >
                  <button class="button is-black has-shadow">
                    <div class="text-is-red menu-text">Order Online</div>
                  </button>
                </a>
              </li>
            </ul>
          </div>
          <div class="column is-one-half">
            <div class="level has-dotted-border-bottom">
              <div
                class="
                  level-left level-item
                  title
                  is-5
                  menu-text menu-size
                  text-is-red
                  mb-0
                "
              >
                Directions
              </div>
              <div
                class="
                  level-right level-item
                  menu-text menu-price
                  text-is-red
                  mb-0
                "
              ></div>
            </div>
            <div class="map-responsive">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2906.0139338841846!2d-79.84342388391958!3d43.251129779137294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c9bef8d144423%3A0x1a86c9850453c325!2sQueens%20Pizza%20%26%20Wings!5e0!3m2!1sen!2sca!4v1598061956769!5m2!1sen!2sca"
                width="600"
                height="450"
                frameborder="0"
                style="border: 0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
                title="Google Maps"
              ></iframe>
            </div>
            <ul class="has-text-centered pt-5">
              <li class="is-uppercase menu-text menu-description is-size-7">
                All prices are subject to applicable taxes
              </li>
              <li class="is-uppercase menu-text menu-description is-size-7">
                Prices are subject to change without notice
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <footer class="footer has-background-black">
      <div class="content has-text-centered">
        <div class="menu-text text-is-red">2020 - Queen's Pizza & Wings</div>
        <div class="menu-text text-is-red">
          Designed by <a href="#">David Nguyen</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css";
@import "https://fonts.googleapis.com/css2?family=Pacifico&family=Roboto+Mono&display=swap";
@import "./assets/css/styles.min.css";

.center-button {
  @apply columns;
}
</style>
